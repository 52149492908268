import { Injectable } from '@angular/core';
import { RegionType } from '~type/service/geo/region';

import { OperationType } from '~type/service/utility/operation';

import {
	landfills,
	metaRegions,
	operationSteps,
	OperationStepsType,
	providerNames,
	RegionsSimulator,
	simulatorByZone,
} from './constants/ut.constants';

@Injectable({ providedIn: 'root' })
export class UtService {
	private readonly metaRegions: RegionType.Region[] = metaRegions;
	private readonly landfills = landfills;
	private readonly providerNames = providerNames;
	private readonly operationSteps: OperationStepsType = operationSteps;
	private readonly simulatorByZone: RegionsSimulator = simulatorByZone;

	public getLandfills(keyValue: boolean = false): string[][] | { key: string; name: string }[] {
		if (!keyValue) { return this.landfills; }
		return this.landfills.map(([name, type]) => ({ key: name.trim(), name: name.trim(), type }));
	}

	public getProviderNames(keyValue: boolean = false): string[] | { key: string; name: string }[] {
		if (!keyValue) { return this.providerNames; }
		return this.providerNames.map((name) => ({ key: name.trim(), name: name.trim() }));
	}

	public getOperationSteps(): { status: OperationType.Status }[] {
		return OperationType.STATUS_SORTING.map((status) => ({
			...this.operationSteps[status],
			status: status,
		}));
	}

	public getSimulatorByZone(): RegionsSimulator { return this.simulatorByZone; }

	public getMetaRegions(): RegionType.Region[] { return this.metaRegions; }
}
