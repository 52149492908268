import { HttpParams } from '@angular/common/http';

import qs from 'qs';

interface Params { [k: string]: any; }

export class HttpParamsQS extends HttpParams {
	public readonly __params: Params;

	constructor({ fromObject = {} }: { fromObject: Params }) {
		super();
		this.__params = fromObject;
	}

	public toString(): string {
		return qs.stringify(this.__params, { encodeValuesOnly: true });
	}
}
