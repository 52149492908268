import { OrderType } from '../order/order';
import { TrucksType } from './trucks';
import { StorageFileType } from '../storage/file';


export namespace OperationType {
	export interface Operation {
		id: string;

		regionId: string;
		orderId: string;
		truckId: string;
		truck: TrucksType.Trucks;

		status: Status;
		statutes: Partial<Record<OperationType.Status, {
			createdAt: Date;
		}>>;

		content: Contents.Type | null;

		comment: string | null;

		meta: {
			times: {
				travel?: {
					time: number;
					type: 'AUTO' | 'MANUAL';
				};
				operation?: {
					time: number;
					type: 'AUTO' | 'MANUAL';
				};
			};
			forecasts: {
				operation?: {
					value: 'LARGER' | 'CORRECT' | 'SMALLER';
				};
			};
			handler: {
				count: number;
				comment: string | null;
				leftovers: string | null;
			};
			rep: {
				enabled: boolean;
			};
		};

		prices: {
			main: number;
			final: number;
			finalVat: number;
			vat: number;
		} | null;

		files: {
			sha256: File.OperationFile['sha256'];
			category: File.OperationFile['category'];
		}[];

		createdAt: Date;
		updatedAt: Date;
	}

	export enum Status {
		STARTED = 'STARTED',
		CUSTOMER_CONTACTED = 'CUSTOMER_CONTACTED', // +TRUCK_ARRIVED
		TRAVEL_TIME_ADDED = 'TRAVEL_TIME_ADDED',
		OPERATION_PICTURES_TAKED_BEFORE = 'OPERATION_PICTURES_TAKED_BEFORE',
		OPERATION_FORECAST_CHECKED = 'OPERATION_FORECAST_CHECKED', // +HANDLING_COMPLETED
		OPERATION_PICTURES_TAKED_AFTER_OPTIONAL = 'OPERATION_PICTURES_TAKED_AFTER_OPTIONAL', // Optional
		OPERATION_CONTENT_ADDED = 'OPERATION_CONTENT_ADDED',
		OPERATION_PRICE_ADDED = 'OPERATION_PRICE_ADDED',
		OPERATION_VALIDATED = 'OPERATION_VALIDATED',
		OPERATION_PICTURES_TAKED_AFTER = 'OPERATION_PICTURES_TAKED_AFTER',
		TRUCK_PICTURES_TAKED = 'TRUCK_PICTURES_TAKED',
		INTERNAL_META_ADDED = 'INTERNAL_META_ADDED',
		OPERATION_TIME_ADDED = 'OPERATION_TIME_ADDED',
		DONE = 'DONE',
	}

	export const STATUS_SORTING: Status[] = [
		Status.STARTED,
		Status.CUSTOMER_CONTACTED,
		Status.TRAVEL_TIME_ADDED,
		Status.OPERATION_PICTURES_TAKED_BEFORE,
		Status.OPERATION_FORECAST_CHECKED,
		Status.OPERATION_PICTURES_TAKED_AFTER_OPTIONAL, // Optional
		Status.OPERATION_CONTENT_ADDED,
		Status.OPERATION_PRICE_ADDED,
		Status.OPERATION_VALIDATED,
		Status.OPERATION_PICTURES_TAKED_AFTER,
		Status.TRUCK_PICTURES_TAKED,
		Status.INTERNAL_META_ADDED,
		Status.OPERATION_TIME_ADDED,
		Status.DONE,
	];

	export namespace Contents {
		export type Type = OrderType.Contents.Utility.Content & {
			options: OrderType.Contents.Utility.Content['options'] & {
				'UTILITY_HEAVY_BAG'?: true;
				'UTILITY_PAV'?: true;
				'UTILITY_FIXED_PRICE'?: {
					price: number;
				};
			};
		};

		export enum Groups {
			UTILITY_BAG = 'UTILITY_BAG',
			UTILITY_VOLUME = 'UTILITY_VOLUME',
			UTILITY_OBJECT = 'UTILITY_OBJECT',
		}

		export const GROUPS = {
			[Groups.UTILITY_BAG]: {
				name: 'SERVICE.UTILITY.CONTENT.GROUPS.UTILITY_BAG.NAME',
			},
			[Groups.UTILITY_VOLUME]: {
				name: 'SERVICE.UTILITY.CONTENT.GROUPS.UTILITY_VOLUME.NAME',
			},
			[Groups.UTILITY_OBJECT]: {
				name: 'SERVICE.UTILITY.CONTENT.GROUPS.UTILITY_OBJECT.NAME',
			},
		};
	}

	export namespace File {
		export interface OperationFile {
			operationId: Operation['id'];
			sha256: StorageFileType.File['sha256'];

			category: Category;

			createdAt: Date;
			updatedAt: Date;
		}

		export enum Category {
			PICTURE_OPERATION_BEFORE = 'PICTURE_OPERATION_BEFORE',
			PICTURE_OPERATION_AFTER = 'PICTURE_OPERATION_AFTER',
			PICTURE_CUSTOMER_SIGNATURE = 'PICTURE_CUSTOMER_SIGNATURE',
			PICTURE_TRUCK = 'PICTURE_TRUCK',
		}
	}
}
