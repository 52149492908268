import { RunWasteType } from '~app/types/run';
import { RegionType } from '~type/service/geo/region';
import { OperationType } from '~type/service/utility/operation';


type NotDone = Omit<{ [k in keyof typeof OperationType.Status]: { icon: string, title: string } }, 'DONE'>;
export type OperationStepsType = NotDone & { [k in 'DONE']?: never };

export interface RegionData {
	readonly iconRight: 'adjust' | 'hdr_weak';
	readonly id: string;
	readonly title: string;
}

export interface RegionsSimulator {
	readonly FR_IDF: readonly RegionData[];
	readonly FR_LYON: readonly RegionData[];
	readonly 'FR_AIX-MARSEILLE-PROVENCE': readonly RegionData[];
}

export const landfills = [
	[`DERICHEBOURG IVRY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG NANTERRE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG NOISY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG CHATILLON`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG BONNEUIL`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG CORBEIL-ESSONNES`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG ARGENTEUIL`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG CHELLES`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG LIMAY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG NEMOUR`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG GENNEVILLIERS`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`DERICHEBOURG LA COURNEUVE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PAPREC COURNEUVE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`VAREA IVRY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PICHETA NANTERRE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PICHETA PIERRELAYE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PICHETA LIMAY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PICHETA TRIEL SUR SEINE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`PICHETA ACHERES`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`SMS ECOTRI L'HAY-LES-ROSES`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`COSSON PLATEFORME ECOTRI LA COURNEUVE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`SOLARZ LE BOURGET`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`LUXO BENNES 93450 SAINT DENIS`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`LUXO BENNES MASSY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`LUXO BENNES Vitry`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`LUXO BENNES PONT CARRE`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`LUSOFER VITRY`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`SADLOC VILLENEUVE LE ROI`, RunWasteType.WEIGHT, 'FR_IDF'],
	[`BUTY SERVICES LYON`, RunWasteType.WEIGHT, 'FR_LYON'],
	[`ECOTRI LYON`, RunWasteType.WEIGHT, 'FR_LYON'],
	[`SERFIM LYON (7EME)`, RunWasteType.WEIGHT, 'FR_LYON'],
	[`SERFIM LYON (ST PRIEST)`, RunWasteType.WEIGHT, 'FR_LYON'],
	[`MAT'ILD BOUC BEL AIR`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`MAT'ILD MARSEILLE 15`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`PURFER DERICHEBOURG MARSEILLE 11`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
	[`PURFER DERICHEBOURG MARSEILLE 14`, RunWasteType.WEIGHT, 'FR_AIX-MARSEILLE-PROVENCE'],
];

export const providerNames = ['GIRALIFT', 'SABRI HLB', 'PZ TRANSPORT', 'GKTDE', 'AUTRE'];

export const operationSteps: OperationStepsType = {
	[OperationType.Status.STARTED]: {
		icon: 'phone',
		title: `Cordonnées client`,
	},
	[OperationType.Status.CUSTOMER_CONTACTED]: {
		icon: 'timer',
		title: `Temps de trajet`,
	},
	[OperationType.Status.TRAVEL_TIME_ADDED]: {
		icon: 'photo_camera',
		title: `Photos avant l'opération`,
	},
	[OperationType.Status.OPERATION_PICTURES_TAKED_BEFORE]: {
		icon: 'assignment_turned_in',
		title: `Conformité de l'opération`,
	},
	[OperationType.Status.OPERATION_FORECAST_CHECKED]: {
		icon: 'photo_camera',
		title: `Photos après l'opération`,
	},
	[OperationType.Status.OPERATION_PICTURES_TAKED_AFTER_OPTIONAL]: {
		icon: 'timeline',
		title: `Ajustement au réel`,
	},
	[OperationType.Status.OPERATION_CONTENT_ADDED]: {
		icon: 'euro',
		title: `Calcul du prix`,
	},
	[OperationType.Status.OPERATION_PRICE_ADDED]: {
		icon: 'receipt',
		title: `Validation du reçu`,
	},
	[OperationType.Status.OPERATION_VALIDATED]: {
		icon: 'photo_camera',
		title: `Photos après l'opération`,
	},
	[OperationType.Status.OPERATION_PICTURES_TAKED_AFTER]: {
		icon: 'photo_camera',
		title: `Photos du camion`,
	},
	[OperationType.Status.TRUCK_PICTURES_TAKED]: {
		icon: 'notes',
		title: `Détails interne`,
	},
	[OperationType.Status.INTERNAL_META_ADDED]: {
		icon: 'timer',
		title: `Temps de l'opération`,
	},
	[OperationType.Status.OPERATION_TIME_ADDED]: {
		icon: 'check',
		title: `Fin de l'opération`,
	},
};

export const simulatorByZone: RegionsSimulator = {
	FR_IDF: [
		{
			iconRight: 'adjust',
			title: 'Paris et Intra Francilienne',
			id: 'SI-8Z8DKE',
		},
		{
			iconRight: 'hdr_weak',
			title: 'Hors Francilienne',
			id: 'SI-8Z8DTS',
		},
		{
			iconRight: 'adjust',
			title: '[REP] Paris et Intra Franci.',
			id: 'SI-925AKX',
		},
		{
			iconRight: 'hdr_weak',
			title: '[REP] Hors Francilienne',
			id: 'SI-925AFY',
		},
	],
	FR_LYON: [
		{
			iconRight: 'adjust',
			title: 'Lyon et petite couronne',
			id: 'SI-96S9DX',
		},
		{
			iconRight: 'hdr_weak',
			title: 'Grand Lyon',
			id: 'SI-97UREL',
		},
		{
			iconRight: 'adjust',
			title: '[REP] Lyon et petite couronne',
			id: 'SI-96S9RY',
		},
		{
			iconRight: 'hdr_weak',
			title: '[REP] Grand Lyon',
			id: 'SI-97URLD',
		},
	],
	'FR_AIX-MARSEILLE-PROVENCE': [
		{
			iconRight: 'adjust',
			title: 'Marseille',
			id: 'SI-979KX4',
		},
		{
			iconRight: 'hdr_weak',
			title: 'Aubagne, Vitrolles, Aix-en-Provence, La Ciotat',
			id: 'SI-979L26',
		},
		{
			iconRight: 'adjust',
			title: '[REP] Marseille',
			id: 'SI-979L66',
		},
		{
			iconRight: 'hdr_weak',
			title: '[REP] Aubagne, Vitrolles, Aix-en-Provence, La Ciotat',
			id: 'SI-979KXR',
		},
	],
} as const;

export const metaRegions: RegionType.Region[] = [
	{
		id: 'FR_IDF',
		name: 'Île-de-France',
		config: {
			timezone: 'Europe/Paris',
			country: 'FR',
			locale: 'fr-FR',
			currency: 'EUR',
			vat: 20.0,
		},
	},
	{
		id: 'FR_LYON',
		name: 'Lyon',
		config: {
			timezone: 'Europe/Paris',
			country: 'FR',
			locale: 'fr-FR',
			currency: 'EUR',
			vat: 20.0,
		},
	},
	{
		id: 'FR_AIX-MARSEILLE-PROVENCE',
		name: 'Marseille',
		config: {
			timezone: 'Europe/Paris',
			country: 'FR',
			locale: 'fr-FR',
			currency: 'EUR',
			vat: 20.0,
		},
	},
	{ // Région de test
		id: 'TEST',
		name: 'Test (Greater London)',
		config: {
			timezone: 'Europe/London',
			country: 'GB',
			locale: 'en-GB',
			currency: 'GBP',
			vat: 10.0,
		},
	},
];
