import './polyfills';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from 'src/environments/environment';

import { AppModule } from '~app/app.module';

if (environment.production) enableProdMode();

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(console.error);
