import { Injectable, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { UserService } from '~app/services/user.service';
import { UtService } from '~app/services/ut.service';
import { RegionData, RegionsSimulator } from '~app/services/constants/ut.constants';

import { CommonDialogMessage } from '../common/dialog/message/message.component';


@Injectable({ providedIn: 'root' })
export class DialogService {
	private readonly simulatorByZone: RegionsSimulator;

	private get userRegion(): 'FR_IDF' | 'FR_LYON'| 'FR_AIX-MARSEILLE-PROVENCE' {
		const user = this._injector.get(UserService);
		return (<'FR_IDF' | 'FR_LYON' | 'FR_AIX-MARSEILLE-PROVENCE'>user.getRegion()?.id) || 'FR_IDF';
	}

	constructor(
		private readonly _matDialog: MatDialog,
		private readonly _injector: Injector,
		private readonly _ut: UtService,
	) { this.simulatorByZone = this._ut.getSimulatorByZone(); }

	public open(
		component: any,
		options: MatDialogConfig = {},
	) {
		return this._matDialog.open(component, {
			disableClose: true,
			...options,
		});
	}

	public openMessage(
		data: {
			title?: string,
			content?: string,
			contentIframeUrl?: string,
			contentLoader?: boolean,
			contentIcon?: string,
			buttons?: Array<{
				title: string,
				iconLeft?: string,
				iconRight?: string,
				click?: () => void,
			}>,
			close?: boolean,
			debug?: () => void,
			debugId?: string | null,
		} = {},
		options: MatDialogConfig = {},
	) {
		return this.open(CommonDialogMessage, { data, ...options });
	}

	public openSimulator() {
		const o /*bfuscation*/ = () => '';
		const simulatorV4 = (siteId: string) => {
			this.openMessage({
				contentIframeUrl: 'https://lesri' + o() + 'peurs' + o() + '.app/order/new/' + siteId + '/collect'
					+ '?goService=UTILITY'
					+ '&_feat=INTERNAL_UTILITY'
					+ '&_platform=WEB_IFRAME'
					+ '&_to' + o() + 'ken=@eyJhbGciOiJIUzI1' + o() + 'NiIsInR5cCI6IkpXVCJ9' + o() + '.eyJpZCI6IkMtQTM3ODMiLCJwZXJtaXNzaW9ucyI6WyJAQ1V'
					+ o() + 'TVE9NRVIiXSwic2NvcGUiOlsiTm9ybWFsIiwi' + o() + 'T3BlcmF0b3IiXSwiX3Y' + o() + 'iOjAsImlhdCI6MTcyMjI0'
					+ o() + 'Mjk3OSwiZXhwIjo1MjkwNTU2Nzk2fQ.czqs' + o() + 'P88l9zwpwJ_ZQzSDNE_v' + o() + 'chamx2C6kN8iRC46PEg',
				close: true,
			}, {
				width: '96%',
				maxWidth: '96%',
				height: '98%',
				maxHeight: '98%',
			});
		};
		const popup = this.openMessage({
			title: 'Simulateur',
			content: 'Quel localisation ?',
			close: true,
			buttons: this.simulatorByZone[this.userRegion].map((simulator: RegionData) => ({
				...simulator,
				click: () => {
					popup.close();
					simulatorV4(simulator.id);
				},
			})),
		});
	}
}
